export const fonts = {
    lato: '"Lato", Helvetica Neue, Helvetica, Arial, sans-serif',
    filson: 'filson-pro, sans-serif',
}

export const fontWeights = {
    bold: 700,
    semi: 600,
    reg: 400,
    light: 300,
}

export const fontSizes = ['8px', '12px', '18px']
fontSizes['xl'] = '20px'
fontSizes.big = '18px'
fontSizes.medium = '16px'
fontSizes.normal = '12px'
fontSizes.small = '8px'
fontSizes.instructions = '14px'

export const letterSpacings = {}
