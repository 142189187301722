import colors from './colors'
import space from './space'
import { fonts, fontWeights, fontSizes, letterSpacings } from './fonts'
import { borders } from './borders'

const breakpoints = ['544px', '991px', '1024px', '1200px']
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const radius = {
    radius: '3px',
    radiusxl: '6px',
    radiusxxl: '9px',
}

export default {
    breakpoints,
    colors,
    fonts,
    fontWeights,
    fontSizes,
    letterSpacings,
    space,
    borders,
    ...radius,
}
