const colors = {
    ltblue1: '#eff5f9',
    'ltblue1-2': '#f7fbfd',
    'ltblue1-3': '#f2f9fc',
    'ltblue1-4': '#F1FAFF',
    'ltblue1-5': '#e9eff4',
    'ltblue1-6': '#eaf3f9',
    ltblue2: '#daeaf2',
    ltblue3: '#cce1ed',
    ltblue4: '#629ccc',
    ltblue5: '#80c0e5',
    ltblue6: '#e0f5ff',
    ltblue8: '#E1F4FA',

    blue: '#4A90E2',
    blue1: '#3074C4',
    bluedk: '#49698F',
    bluelt: '#F3FAFF',
    bluelt1: '#E2F1FC',
    bluelt2: '#94A5B4',

    dkblue1: '#037FD0',
    dkblue2: '#005f99',
    dkblue3: '#1C4D76',
    dkblue4: '#1A2D42',

    shqblue: '#02c6f4',
    shqblue2: '#0391D9',
    greybluelt: '#00aae5',
    greybluelt1: '#2d97d3',
    greybluelt2: '#3b90ce',
    greybluelt4: '#6A7685',
    //'greybluelt2': '#008cbe',

    greybluedk: '#596675',
    greybluedk1: '#384551',
    greybluedk2: '#6a7685',
    greybluedk3: '#969da6',

    green: '#4FCA7B',
    green1: '#2ba354',
    green2: '#44BC5E',
    green3: '#58c97d',
    greendk: '#1a793a',
    greenlt1: '#eef9f1',
    greenlt2: '#d5eed7',
    shqgreen: '#1ed397',

    greyfa: '#fafafa',
    greyf9: '#f9f9f9',
    greyf8: '#f8f8f8',
    greyf5: '#f5f5f5',
    greyf3: '#f3f3f3',
    greyf1: '#f1f1f1',
    greyef: '#efefef',
    greye9: '#e9e9e9',
    greyde: '#dedede',
    greya: '#aaa',
    greyb: '#bbb',
    greyc: '#ccc',
    greyd: '#ddd',
    greye: '#eee',
    grey8: '#888',
    grey7: '#777',
    grey6: '#666',
    grey5: '#555',
    grey3: '#333',
    grey2: '#222',
    grey1: '#111',

    shqred: '#ff5652',
    shqred2: '#ea524f',
    shqred3: '#d80505',
    redlt1: '#ffebea',
    redlt2: '#ffd1cf',

    orangelt: '#FFF5EC',
    orange: '#f77746',
    orangedk: '#ff4d24',

    yellowlt: '#fffaf2',
    yellowlt2: '#FFF6E6',
    yellow: '#FFF6E6',
    yellowdk: '#FFE6B7',
    yellowdk1: '#FFA652',
    yellowdk2: '#ff9939',

    purple: '#8e66e4',
    purpledk1: '#785fdf',
    purpledk2: '#5a55d7',
    purplelt1: '#e2d4ff',
    purplelt2: '#bd9dff',
    purplelt3: '#e2d4ff80',

    white: '#fff',

    // social colors
    twitterc: '#00aeef',
    facebookc: '#0072bc',
    googlec: '#df4a32',

    ltblue13: '#f2f9fc',

    'primary-orange': '#f77747',
}

colors.infoLink = colors.dkblue1

export default colors
